export const ranks = {
  AD: {
    id: "AD",
    description: "Gekündigt",
    group: "",
    badge: "/assets/img/ranks/dismissed.png",
    value: -1,
  },
  CA: {
    id: "CA",
    description: "Polizeianwärter",
    group: "recruit",
    badge: "/assets/img/ranks/0.png",
    value: 0,
  },
  C1: {
    id: "C1",
    description: "Polizeiwachtmeister",
    group: "middle",
    badge: "/assets/img/ranks/1.png",
    value: 1,
  },
  C2: {
    id: "C2",
    description: "Polizeimeister",
    group: "middle",
    badge: "/assets/img/ranks/2.png",
    value: 2,
  },
  C3: {
    id: "C3",
    description: "Polizeiobermeister",
    group: "middle",
    badge: "/assets/img/ranks/3.png",
    value: 3,
  },
  C4: {
    id: "C4",
    description: "Polizeihauptmeister",
    group: "middle",
    badge: "/assets/img/ranks/4.png",
    value: 4,
  },
  C5: {
    id: "C5",
    description: "Polizeihauptmeister m. Z.",
    group: "middle",
    badge: "/assets/img/ranks/5.png",
    value: 5,
  },
  C6: {
    id: "C6",
    description: "Polizeikommissaranwärter",
    group: "upper",
    badge: "/assets/img/ranks/6.png",
    value: 6,
  },
  C7: {
    id: "C7",
    description: "Polizeikommissar",
    group: "upper",
    badge: "/assets/img/ranks/7.png",
    value: 7,
  },
  C8: {
    id: "C8",
    description: "Polizeioberkommissar",
    group: "upper",
    badge: "/assets/img/ranks/8.png",
    value: 8,
  },
  C9: {
    id: "C9",
    description: "Polizeihauptkommissar",
    group: "upper",
    badge: "/assets/img/ranks/9.png",
    value: 9,
  },
  C10: {
    id: "C10",
    description: "Erster Polizeihauptkommissar",
    group: "upper",
    badge: "/assets/img/ranks/10.png",
    value: 10,
  },
  C11: {
    id: "C11",
    description: "Polizeirat",
    group: "higher",
    badge: "/assets/img/ranks/11.png",
    value: 11,
  },
  C12: {
    id: "C12",
    description: "Polizeioberrat",
    group: "higher",
    badge: "/assets/img/ranks/12.png",
    value: 12,
  },
  C13: {
    id: "C13",
    description: "Polizeidirektor",
    group: "higher",
    badge: "/assets/img/ranks/13.png",
    value: 13,
  },
  C14: {
    id: "C14",
    description: "Leitender Polizeidirektor",
    group: "higher",
    badge: "/assets/img/ranks/14.png",
    value: 14,
  },
  C15: {
    id: "C15",
    description: "Polizeipräsident",
    group: "higher",
    badge: "/assets/img/ranks/15.png",
    value: 15,
  },
};

export const getRankFromTag = (string) => /(?<=\[)(.*?)(?=\/)/.exec(string)[0];

export const functions = [
  "Zweitfraktion",
  "Staatsanwaltschaft",
  "Strafsenat",
  "Verkehrsüberwachung",
  "Zoll",
  "Kriminalpolizei",
  "Einsatzkommando",
  "Rekrutierer",
  "Ausbilder",
  "Fortbilder",
  "Personalrat",
  "Polizeiführung",
  "Polizeileitung",
];

export const status = [
  "Normal",
  "Entschuldigt",
  "Unter Beobachtung",
  "Suspendiert",
  "Gekündigt",
];

export const activityStatus = ["Aktiv", "Inaktiv"];

export const eventTypes = [
  "Modul",
  "Einsatztraining",
  "Fachbereichstraining",
  "Sonstiges",
];

export const ratingFunctions = [
  "Streifendienst",
  "Leitstelle",
  "MEZ",
  "Einsatzleitung",
  "Verhandlungsführer",
  "Selbstreflektion",
];

export const serverPeriods = [
  "00:00 - 06:00 Uhr",
  "06:00 - 12:00 Uhr",
  "12:00 - 18:00 Uhr",
  "18:00 - 00:00 Uhr",
];
