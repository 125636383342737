import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useGetCopsQuery } from "./copsApiSlice";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  useTheme,
} from "@mui/material";
import { getCopStatusColor } from "util/muiColorGetter";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { dataGridSx } from "styles/dataGridSx";
import { hasPermission } from "util/permissionHelper";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";
import CopRankBadge from "components/CopRankBadge";
import CopFunctions from "features/cops/CopFunctions";
import CustomGridPagination from "components/CustomGridPagination";
import { activityStatus, ranks, status } from "util/copEnums";
import IsOnlineBadge from "components/IsOnlineBadge";
import RequirePermissionForRender from "features/auth/RequirePermissionForRender";
import RotatedExpandIcon from "components/RotatedExpandIcon";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddCopDialog from "./AddCopDialog";
import DismissedCopDataGrid from "./DismissedCopDataGrid";
import { calcGridHeightSubtraction } from "util/calculations";

const GridToolbar = () => {
  const [expanded, setExpanded] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <GridToolbarContainer sx={{ pr: 0 }}>
        <GridToolbarQuickFilter />
        <RequirePermissionForRender
          permissions={["reappointCops", "seeDismissedCops"]}
        >
          <Button
            onClick={() => setExpanded((value) => !value)}
            variant="contained"
            sx={{ mb: 1 }}
          >
            <Box mr="8px">Gekündigte Polizisten</Box>
            <RotatedExpandIcon expand={expanded} />
          </Button>
        </RequirePermissionForRender>
        <RequirePermissionForRender permissions={[]}>
          <Button
            sx={{ mb: 1 }}
            variant="contained"
            onClick={() => setDialogOpen(true)}
            startIcon={<AddOutlinedIcon />}
          >
            Polizist hinzufügen
          </Button>
        </RequirePermissionForRender>
      </GridToolbarContainer>
      <RequirePermissionForRender
        permissions={["reappointCops", "seeDismissedCops"]}
      >
        <Box>
          <Collapse in={expanded} timeout="auto" unmountOnExit={false}>
            <Box sx={{ mb: 2 }}>
              <DismissedCopDataGrid />
            </Box>
          </Collapse>
        </Box>
      </RequirePermissionForRender>
      <RequirePermissionForRender permissions={[]}>
        <AddCopDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
      </RequirePermissionForRender>
    </>
  );
};

const renderRank = (params) => <CopRankBadge rank={params.value} />;

const renderName = (params) => (
  <Box display="flex" alignItems="center">
    <IsOnlineBadge
      badgeContent={!!params.row.online ? 1 : 0}
      variant="dot"
      overlap="circular"
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      sx={{
        "& .MuiBadge-badge": {
          right: 16,
        },
      }}
      color={params.row.online?.side === "civ" ? "warning" : undefined}
    >
      <Avatar
        alt="steam-avatar"
        src={params.row.user?.steamData?.avatarfull}
        sx={{
          height: "35px",
          width: "35px",
          mr: "12px",
        }}
      />
    </IsOnlineBadge>
    {params.value}
  </Box>
);

const rankComparator = (a, b) => ranks[a].value - ranks[b].value;

const renderStatus = (params) =>
  !!params.value ? (
    <Chip
      label={params.value}
      color={getCopStatusColor(params.value)}
      sx={{ m: "12px 0" }}
    />
  ) : (
    <></>
  );

const renderIsInvisible = (params) => (
  <Checkbox defaultChecked={params.value} disableRipple />
);

const renderFunctions = (params) => (
  <CopFunctions functions={params.value} readOnly hideOutlines hideLabel />
);

const CopDataGrid = () => {
  const theme = useTheme();
  const { roles: userRoles } = useAuth();
  const navigate = useNavigate();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const {
    data: cops,
    isSuccess,
    isLoading,
  } = useGetCopsQuery("copsList", {
    refetchOnMountOrArgChange: true,
  });

  const [rows, setRows] = useState([]);
  const [copEditPermissions, setCopEditPermissions] = useState(true);
  const [copViewPermissions, setCopViewPermissions] = useState(true);

  useEffect(() => {
    const hasEditPermissions = hasPermission({
      roles: userRoles,
      permissions: ["editCops"],
    })
      ? "cop"
      : hasPermission({
          roles: userRoles,
          permissions: ["editRecruits"],
        })
      ? "recruit"
      : false;

    const hasViewPermissions = hasPermission({
      roles: userRoles,
      permissions: ["seeCopData"],
    })
      ? "cop"
      : hasPermission({
          roles: userRoles,
          permissions: ["seeRecruitData"],
        })
      ? "recruit"
      : false;

    setCopEditPermissions(hasEditPermissions);
    setCopViewPermissions(hasViewPermissions);
  }, [userRoles]);

  useEffect(() => {
    if (isSuccess) {
      setRows(
        cops?.ids
          .map((id) => cops.entities[id])
          .filter((cop) => cop.status !== "Gekündigt")
      );
    }
  }, [isSuccess, cops]);

  const columns = useMemo(
    () =>
      copEditPermissions === "cop"
        ? [
            {
              field: "rank",
              headerName: "Rang",
              flex: 1,
              maxWidth: 300,
              renderCell: renderRank,
              sortComparator: rankComparator,
              sortingOrder: ["desc", "asc"],
            },
            {
              field: "user",
              headerName: "Name",
              valueGetter: ({ value }) => value.name,
              renderCell: renderName,
              flex: 1,
              maxWidth: 200,
            },
            {
              field: "number",
              type: "string",
              headerName: "Dienstnr.",
              flex: 1,
              maxWidth: 70,
            },
            {
              field: "status",
              headerName: "Status",
              type: "singleSelect",
              valueOptions: status,
              renderCell: renderStatus,
              flex: 1,
              maxWidth: 120,
            },
            {
              field: "activityStatus",
              headerName: "Aktivitätsstatus",
              type: "singleSelect",
              valueOptions: activityStatus,
              renderCell: renderStatus,
              flex: 1,
              maxWidth: 120,
            },
            {
              field: "functions",
              headerName: "Funktionen",
              flex: 1,
              renderCell: renderFunctions,
            },
            {
              field: "activityLast2Weeks",
              headerName: "Spielstunden (letzte 2 Wochen)",
              type: "number",
              flex: 1,
              maxWidth: 180,
            },
            {
              field: "exp",
              type: "number",
              headerName: "EP",
            },
            {
              field: "isInvisible",
              headerName: "Unsichtbar",
              type: "boolean",
              renderCell: renderIsInvisible,
            },
          ]
        : [
            {
              field: "rank",
              headerName: "Rang",
              flex: 1,
              maxWidth: 300,
              renderCell: renderRank,
              sortComparator: rankComparator,
              sortingOrder: ["desc", "asc"],
            },
            {
              field: "user",
              headerName: "Name",
              valueGetter: ({ value }) => value.name,
              flex: 1,
              maxWidth: 200,
            },
            {
              field: "number",
              type: "string",
              headerName: "Dienstnr.",
              flex: 1,
              maxWidth: 70,
            },
            {
              field: "status",
              headerName: "Status",
              type: "singleSelect",
              valueOptions: status,
              renderCell: renderStatus,
              flex: 1,
              maxWidth: 120,
            },
            {
              field: "activityStatus",
              headerName: "Aktivitätsstatus",
              type: "singleSelect",
              valueOptions: activityStatus,
              renderCell: renderStatus,
              flex: 1,
              maxWidth: 120,
            },
            {
              field: "functions",
              headerName: "Funktionen",
              flex: 1,
              renderCell: renderFunctions,
            },
          ],
    [copEditPermissions]
  );

  const handleRowClick = useCallback(
    (params) => {
      if (
        copEditPermissions === "cop" ||
        copViewPermissions === "cop" ||
        (copEditPermissions === "recruit" && params.row.rank === "CA") ||
        (copViewPermissions === "recruit" && params.row.rank === "CA")
      )
        navigate(`/cops/${params.row.id}`);
    },
    // eslint-disable-next-line
    [copEditPermissions, copViewPermissions]
  );

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),

        "& .row-style--editable": {
          ":hover": {
            cursor: "pointer !important",
          },
        },
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        columns={columns}
        rows={isSuccess ? rows : []}
        loading={isLoading}
        getRowHeight={() => "auto"}
        rowSelection={false}
        {...((!!copEditPermissions || !!copViewPermissions) && {
          onRowClick: handleRowClick,
        })}
        getRowClassName={(params) =>
          (copEditPermissions === "cop" ||
            copViewPermissions === "cop" ||
            (copEditPermissions === "recruit" && params.row.rank === "CA") ||
            (copViewPermissions === "recruit" && params.row.rank === "CA")) &&
          "row-style--editable"
        }
        initialState={{
          sorting: {
            sortModel: [{ field: "rank", sort: "desc" }],
          },
        }}
        slots={{
          pagination: CustomGridPagination,
          toolbar: GridToolbar,
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
      />
    </Box>
  );
};
export default memo(CopDataGrid);
