import { useEffect, useState } from "react";
import { useGetPlayerCountsQuery } from "./playerCountsApiSlice";
import { ResponsiveLine } from "@nivo/line";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";

const PlayerCountsChart = () => {
  const { data: playerCounts, isSuccess } = useGetPlayerCountsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [data, setData] = useState([]);

  const theme = useTheme();

  useEffect(() => {
    if (isSuccess) {
      setData(
        playerCounts.ids.reduce(
          (data, id) => {
            const playerCount = playerCounts.entities[id];
            const createdAt = new Date(playerCount.createdAt);

            data[3].data.push({
              x: createdAt,
              y: playerCount.civs,
            });
            data[2].data.push({
              x: createdAt,
              y: playerCount.cops,
            });
            data[1].data.push({
              x: createdAt,
              y: playerCount.rac,
            });
            data[0].data.push({
              x: createdAt,
              y: playerCount.medics,
            });

            /*
            data[0].data.push({
              x: createdAt,
              y: playerCount.justice,
            });
            */

            return data;
          },
          [
            /*
            {
              id: "Justiz",
              data: [],
            },
            */
            {
              id: "Rettungsdienst",
              data: [],
            },
            {
              id: "RAC",
              data: [],
            },
            {
              id: "Polizei",
              data: [],
            },
            {
              id: "Zivilisten",
              data: [],
            },
          ]
        )
      );
    }
  }, [isSuccess, playerCounts]);

  return (
    <Box display="flex" height="100%">
      <ResponsiveLine
        data={data}
        colors={[
          //theme.palette.secondary.main,
          theme.palette.error.main,
          theme.palette.warning.light,
          theme.palette.cop.main,
          theme.palette.civ.main,
        ]}
        theme={{
          grid: {
            line: {
              stroke: alpha(theme.palette.text.secondary, 0.15),
            },
          },
          axis: {
            legend: {
              text: {
                fill: theme.palette.text.secondary,
              },
            },
            ticks: {
              text: {
                fill: theme.palette.text.secondary,
              },
              line: {
                stroke: alpha(theme.palette.text.secondary, 0.15),
              },
            },
          },
          tooltip: {
            container: {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.default,
            },
          },
          legends: {
            text: {
              fill: theme.palette.text.secondary,
            },
          },
          crosshair: {
            line: {
              stroke: theme.palette.text.primary,
            },
          },
        }}
        margin={{ top: 10, right: 20, bottom: 60, left: 60 }}
        xScale={{
          type: "time",
          precision: "second",
          max: "auto",
          min: "auto",
        }}
        xFormat={(value) => dayjs(value).format("DD.MM.YY HH:mm")}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickValues: 4,
          tickSize: 10,
          tickPadding: 10,
          tickRotation: 0,
          legend: "",
          legendOffset: 0,
          legendPosition: "middle",
          truncateTickAt: 0,
          format: (value) => dayjs(value).format("DD.MM.YY HH:mm"),
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legend: "Spieleranzahl",
          legendOffset: -40,
          legendPosition: "middle",
          truncateTickAt: 0,
          format: (value) => value,
        }}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateY: 60,
            itemsSpacing: 30,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "transparent",
          },
        ]}
        enablePoints={false}
        enableSlices="x"
        enableCrosshair
        curve="basis"
        sliceTooltip={({ slice }) => (
          <Box
            sx={{
              p: "4px 12px",
              backgroundColor: theme.palette.background.default,
              borderRadius: theme.shape.borderRadius / 3,
              boxShadow: theme.shadows[2],
            }}
          >
            <Typography>{slice.points[0].data.xFormatted}</Typography>
            <List dense disablePadding>
              {slice.points.map((point) => (
                <ListItem disablePadding key={point.id} alignItems="center">
                  <Box
                    width="10px"
                    height="10px"
                    mr={2}
                    sx={{
                      backgroundColor: point.serieColor,
                    }}
                  />
                  <ListItemText primary={point.serieId} />
                  <Box display="flex" minWidth="20px" ml={2}>
                    <Typography fontWeight="bold" textAlign="right">
                      {point.data.y}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      />
    </Box>
  );
};
export default PlayerCountsChart;
