import {
  Box,
  CircularProgress,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { useGetOnlineCopsQuery } from "./panthorApiSlice";
import { Link } from "react-router-dom";
import { getRankFromTag, ranks } from "util/copEnums";
import DesertSvg from "components/DesertSvg";
import CopRankBadge from "components/CopRankBadge";

const CopsOnlineList = () => {
  const theme = useTheme();

  const {
    data: onlineCops,
    isSuccess,
    isLoading,
  } = useGetOnlineCopsQuery("onlineCopsList", {
    pollingInterval: 60000, // 1 Minute,
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ data, isSuccess, isLoading }) => ({
      data: data?.map((server) => ({
        ...server,
        cops: [...server?.cops]?.sort(
          (a, b) =>
            (ranks[getRankFromTag(b.name)]?.value || 0) -
            (ranks[getRankFromTag(a.name)]?.value || 0)
        ),
      })),
      isSuccess,
      isLoading,
    }),
  });

  if (isLoading) return <CircularProgress />;

  return isSuccess && onlineCops.some((server) => server.cops?.length > 0) ? (
    <Grid container>
      {onlineCops.map((server) => (
        <Grid key={server.id} item xs>
          <Grid container>
            {server.cops?.map((cop, index) => (
              <Grid item xs={12} xl={6} key={index}>
                {!!cop.id ? (
                  <Link
                    to={`/cops/${cop.id}`}
                    style={{
                      color: theme.palette.text.primary,
                      textDecoration: "none",
                    }}
                  >
                    <ListItemButton>
                      <CopRankBadge rank={cop.rank} hideTitle height="30px" />
                      <ListItemText
                        primary={cop.name}
                        secondary={ranks[cop.rank]?.description}
                        sx={{ ml: 1 }}
                      />
                    </ListItemButton>
                  </Link>
                ) : (
                  <ListItem>
                    <CopRankBadge rank={cop.rank} hideTitle height="30px" />
                    <ListItemText
                      primary={cop.name}
                      secondary={ranks[cop.rank]?.description}
                      sx={{ ml: 1 }}
                    />
                  </ListItem>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <DesertSvg
        height="60%"
        width="60%"
        fill={alpha(theme.palette.primary.main, 0.3)}
        stroke={theme.palette.background.paper}
        strokeWidth="5px"
      />
      <Typography
        variant="h6"
        color={theme.palette.text.secondary}
        sx={{
          mb: 8,
        }}
      >
        Derzeit ist niemand da...
      </Typography>
    </Box>
  );
};
export default CopsOnlineList;
