import dayjs from "dayjs";

export const applicationTexts = {
  invitation: (name, interviewDate, recruiterName) => `Hallo ${name},
    
    Danke für Deine Bewerbung. Du hast unser Interesse geweckt und wir möchten dich gerne am ${dayjs(
      interviewDate
    ).format("DD.MM.YYYY")}, um ${dayjs(interviewDate).format(
    "HH:mm"
  )} Uhr zu einem Bewerbungsgespräch einladen. Komm dazu einfach pünktlich in den entsprechenden Wartebereich im TS.
    Wenn Du an diesem Termin nicht kannst, dann schlage uns bitte Alternativen vor.
    
    Solltest du unentschuldigt fehlen, müssen wir Deine Bewerbung leider ablehnen!
    

    Mit freundlichen Grüßen
    
    ${recruiterName}`,

  acceptance: (name, hiringDate, recruiterName) => `Hallo ${name},
  
  es freut uns Dir mitteilen zu können, dass wir uns dazu entschieden haben Dich einzustellen. Bitte erscheine am ${dayjs(
    hiringDate
  ).format("DD.MM.YYYY")}, um ${dayjs(hiringDate).format(
    "HH:mm"
  )} Uhr zu deiner Einstellung im entsprechenden Wartebereich im TS. 
  Wenn Du an diesem Termin nicht kannst, dann schlage uns bitte Alternativen vor.
  
  Solltest du unentschuldigt fehlen, müssen wir Deine Bewerbung leider ablehnen!
  

  Mit freundlichen Grüßen
  
  ${recruiterName}`,
  rejection: (name, reason, recruiterName) => `Hallo ${name},
  
  leider müssen wir Deine Bewerbung aus folgendem Grund ablehnen:
  
  ${reason}
  
  Du kannst Dich gerne frühestens in zwei Wochen erneut bewerben.
  
  
  Mit freundlichen Grüßen
  
  ${recruiterName}`,
};
