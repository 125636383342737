import {
  Autocomplete,
  Box,
  Button,
  Chip,
  ListItem,
  ListItemIcon,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { memo, useCallback, useEffect, useState } from "react";
import { dataGridSx } from "styles/dataGridSx";
import { useGetExpCalculationsQuery } from "./expCalculationsApiSlice";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import CustomGridPagination from "components/CustomGridPagination";
import { Link } from "react-router-dom";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import duration from "dayjs/plugin/duration";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PlaytimeDialog from "features/playtimes/PlaytimeDialog";
import FunctionsOutlinedIcon from "@mui/icons-material/FunctionsOutlined";
import CircularProgressWithBackground from "components/CircularProgressWithBackground";
import EvaluatedPlaytimeDialog from "./EvaluatedPlaytimeDialog";
import { calcGridHeightSubtraction } from "util/calculations";

dayjs.extend(duration);

const ExpCalculationDataGrid = () => {
  const theme = useTheme();
  const [from, setFrom] = useState(dayjs().subtract(3, "day").startOf("day"));
  const [until, setUntil] = useState(dayjs());
  const [rows, setRows] = useState([]);
  const [playtimeDialogOpen, setPlaytimeDialogOpen] = useState(false);
  const [playtime, setPlaytime] = useState({});
  const [evaluatedPlaytimeDialogOpen, setEvaluatedPlaytimeDialogOpen] =
    useState(false);
  const [evaluatedPlaytime, setEvaluatedPlaytime] = useState({});

  const {
    data: expCalculations,
    isSuccess,
    isLoading,
  } = useGetExpCalculationsQuery(
    {
      from: from?.valueOf() || undefined,
      until: until?.valueOf() || undefined,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      setRows(expCalculations.ids.map((id) => expCalculations.entities[id]));
    }
  }, [isSuccess, expCalculations]);

  const CustomGridToolbar = useCallback(
    () => (
      <GridToolbarContainer sx={{ mb: 1 }}>
        <Box display="flex" height="100%" alignItems="flex-end">
          <GridToolbarQuickFilter sx={{ maxWidth: "200px" }} />
        </Box>
        <DateTimePicker
          label="Von"
          value={from}
          onAccept={(value) => setFrom(value)}
          slotProps={{
            actionBar: {
              actions: ["cancel", "clear", "accept"],
            },
          }}
        />
        <DateTimePicker
          label="Bis"
          value={until}
          onAccept={(value) => setUntil(value)}
          slotProps={{
            actionBar: {
              actions: ["cancel", "clear", "accept"],
            },
          }}
        />
      </GridToolbarContainer>
    ),
    // eslint-disable-next-line
    []
  );

  const renderEvaluatedPlaytimes = useCallback(({ value }) => {
    return (
      <Autocomplete
        fullWidth
        multiple
        limitTags={7}
        noOptionsText="Keine Spielzeiten gewertet"
        disableCloseOnSelect
        disableClearable
        value={value}
        options={value}
        getOptionLabel={(option) =>
          `${dayjs(option.playtime?.start).format(
            "DD.MM.YYYY HH:mm"
          )} - ${dayjs(option.playtime?.end).format("HH:mm")}`
        }
        renderOption={(props, option) => (
          <ListItem {...props}>
            <ListItemIcon>
              <StorageOutlinedIcon />
              <Typography sx={{ ml: 1 }}>
                {`Server ${option.playtime?.server}`}
              </Typography>
            </ListItemIcon>
            <Typography sx={{ ml: 6 }}>
              {`${dayjs(option.playtime?.start).format(
                "DD.MM.YYYY HH:mm"
              )} - ${dayjs(option.playtime?.end).format("HH:mm")}`}
            </Typography>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              size="small"
              startIcon={<AccessTimeOutlinedIcon />}
              onClick={() => {
                setPlaytime(option.playtime);
                setPlaytimeDialogOpen(true);
              }}
            >
              Spielzeit
            </Button>
            <TextField
              label="Berechnete EP"
              size="small"
              defaultValue={option.calculatedExp}
              InputProps={{
                readOnly: true,
              }}
              sx={{
                ml: 6,
              }}
            />
            <TextField
              label="Angerechnete EP"
              size="small"
              defaultValue={option.creditedExp}
              InputProps={{
                readOnly: true,
              }}
              sx={{
                ml: 2,
              }}
            />
            <Tooltip
              arrow
              title={
                <Typography>{`Angerechnet zu ${
                  Math.round(option.creditedPortion * 100 * 100) / 100
                }%`}</Typography>
              }
            >
              <Box ml={2}>
                <CircularProgressWithBackground
                  value={option.creditedPortion * 100}
                  variant="determinate"
                  size={30}
                  thickness={10}
                  color="primary"
                />
              </Box>
            </Tooltip>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              size="small"
              startIcon={<FunctionsOutlinedIcon />}
              onClick={() => {
                setEvaluatedPlaytime(option);
                setEvaluatedPlaytimeDialogOpen(true);
              }}
            >
              Kalkulation
            </Button>
          </ListItem>
        )}
        renderInput={(props) => (
          <TextField
            {...props}
            sx={{
              ".MuiOutlinedInput-notchedOutline": { border: "none" },
              ".MuiInputBase-input": {
                display: "none",
              },
              ".MuiInputBase-root": {
                cursor: "pointer",
              },
            }}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              label={`${dayjs(option.playtime?.start).format(
                "DD.MM.YYYY HH:mm"
              )} - ${dayjs(option.playtime?.end).format("HH:mm")}`}
              onDelete={undefined}
            />
          ))
        }
      />
    );
  }, []);

  const renderCopName = ({ value, row }) => (
    <Link
      to={`/cops/${row.cop?.user?._id}`}
      style={{ color: theme.palette.info.main, textDecoration: "none" }}
    >
      {value}
    </Link>
  );

  const columns = [
    {
      field: "cop",
      headerName: "Name",
      valueGetter: ({ row }) => row.cop?.user?.name,
      renderCell: renderCopName,
      flex: 1,
      maxWidth: 140,
    },
    {
      field: "createdAt",
      headerName: "Erstellt am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 140,
    },
    {
      field: "evaluatedHours",
      headerName: "Gesamtspielstunden",
      type: "number",
      flex: 1,
      maxWidth: 140,
    },
    {
      field: "maxEvaluatedHours",
      headerName: "Max. gewertete Stunden",
      type: "number",
      flex: 1,
      maxWidth: 140,
    },
    {
      field: "grantedExp",
      headerName: "Angerechnete EP",
      type: "number",
      flex: 1,
      maxWidth: 100,
    },
    {
      field: "evaluatedPlaytimes",
      headerName: "Gewertete Spielzeiten",
      renderCell: renderEvaluatedPlaytimes,
      flex: 1,
    },
  ];

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),

        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        columns={columns}
        rows={isSuccess ? rows : []}
        loading={isLoading}
        rowSelection={false}
        disableRowSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        slots={{
          pagination: CustomGridPagination,
          toolbar: CustomGridToolbar,
        }}
      />
      <PlaytimeDialog
        open={playtimeDialogOpen}
        onClose={() => setPlaytimeDialogOpen(false)}
        playtime={playtime}
      />
      <EvaluatedPlaytimeDialog
        open={evaluatedPlaytimeDialogOpen}
        onClose={() => setEvaluatedPlaytimeDialogOpen(false)}
        evaluatedPlaytime={evaluatedPlaytime}
      />
    </Box>
  );
};
export default memo(ExpCalculationDataGrid);
