import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  Typography,
} from "@mui/material";
import { useGetPollsQuery } from "./pollsApiSlice";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import PollDialog from "./PollDialog";
import RotatedExpandIcon from "components/RotatedExpandIcon";
import PollPieChart from "./PollPieChart";
import useAuth from "hooks/useAuth";
import { hasPermission } from "util/permissionHelper";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CustomSearch from "components/CustomSearch";

const PollCard = ({ poll, onClick }) => {
  return (
    <Card sx={{ maxWidth: 220, overflow: "visible" }}>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              mr={1}
              color="text.secondary"
              sx={poll.votes?.length > 0 && { width: 120 }}
            >
              <Typography>
                {dayjs(poll.start).format("DD.MM.YYYY HH:mm")} -
              </Typography>
              <Typography>
                {dayjs(poll.end).format("DD.MM.YYYY HH:mm")}
              </Typography>
            </Box>
            {poll.votes?.length > 0 && (
              <Box display="flex" width="70px" height="70px">
                <PollPieChart
                  votes={poll.votes}
                  legends={[]}
                  margin={{ top: 9, right: 9, bottom: 9, left: 9 }}
                  enableArcLabels={false}
                  cornerRadius={0}
                />
              </Box>
            )}
          </Box>
          <Box mt={1}>
            <Typography noWrap>{poll.title}</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const PollGrid = () => {
  const { user, roles } = useAuth();

  const {
    data: polls,
    isSuccess,
    isLoading,
  } = useGetPollsQuery("pollsList", { refetchOnMountOrArgChange: true });

  const [unfilteredPolls, setUnfilteredPolls] = useState([]);
  const [filteredPolls, setFilteredPolls] = useState([]);

  const [activePolls, setActivePolls] = useState([]);
  const [inactivePolls, setInactivePolls] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [pollData, setPollData] = useState({});

  const [activePollsExpanded, setActivePollsExpanded] = useState(true);
  const [inactivePollsExpanded, setInactivePollsExpanded] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      const rawPolls = polls.ids
        .map((id) => polls.entities[id])
        .sort(
          (a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()
        );
      setFilteredPolls(rawPolls);
      setUnfilteredPolls(rawPolls);
    }
  }, [isSuccess, polls]);

  useEffect(() => {
    setActivePolls(
      filteredPolls.filter(
        (poll) =>
          dayjs(poll.start).isBefore(dayjs()) &&
          dayjs(poll.end).isAfter(dayjs())
      )
    );

    setInactivePolls(
      filteredPolls.filter(
        (poll) =>
          !(
            dayjs(poll.start).isBefore(dayjs()) &&
            dayjs(poll.end).isAfter(dayjs())
          )
      )
    );
  }, [filteredPolls]);

  useEffect(() => {
    if (activePolls.length === 0) {
      setActivePollsExpanded(false);
    } else {
      setActivePollsExpanded(true);
    }
  }, [activePolls]);

  const onPollClick = useCallback(
    (poll) => {
      const userVote = poll.votes?.find(
        (vote) => vote.cop?._id && vote.cop?._id === user.copData?._id
      );

      setPollData({
        ...poll,
        start: dayjs(poll.start),
        end: dayjs(poll.end),
        organizedBy: poll.organizedBy.map((cop) => cop._id),
        userVote: userVote
          ? { vote: userVote.vote, comment: userVote.comment }
          : { vote: "", comment: "" },
      });
      setDialogOpen(true);
    },
    [user.copData._id]
  );

  const handleAdd = useCallback(() => {
    setPollData({
      title: "",
      description: "",
      start: null,
      end: null,
      affectedFunctions: [],
      organizedBy: [user.copData?._id],
      commentRequired: false,
      anonymous: false,
      userVote: {
        vote: "",
        comment: "",
      },
      voteOptions: ["Ja", "Nein", "Enthaltung"],
    });
    setDialogOpen(true);
  }, [user.copData?._id]);

  const handleSearchChanged = useCallback(
    (event) => {
      const searchString = event.target?.value;

      setFilteredPolls(
        unfilteredPolls.filter((poll) =>
          poll.title?.toLowerCase().includes(searchString.toLowerCase())
        )
      );
    },
    [unfilteredPolls]
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Box mb={1}>
        <Grid container spacing={2} alignItems="center">
          {hasPermission({
            roles,
            permissions: [
              "postPollSecondaryFaction",
              "postPollVerkÜ",
              "postPollZoll",
              "postPollKripo",
              "postPollSEK",
              "postPollFortbilder",
              "postPollRekrutierer",
              "postPollAusbilder",
              "postPollPersonalrat",
              "postPollFührung",
              "postPollLeitung",
              "postPollAll",
            ],
          }) && (
            <Grid item>
              <Button
                variant="contained"
                onClick={handleAdd}
                startIcon={<AddOutlinedIcon />}
              >
                Neue Abstimmung
              </Button>
            </Grid>
          )}
          <Grid item>
            <CustomSearch onChange={handleSearchChanged} />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Button
          variant="text"
          endIcon={<RotatedExpandIcon expand={activePollsExpanded} />}
          onClick={() => setActivePollsExpanded((value) => !value)}
          disabled={activePolls.length === 0}
          sx={{
            mb: 1,
          }}
        >
          <Typography>Offen ({activePolls.length}) </Typography>
        </Button>
        <Collapse in={activePollsExpanded} timeout="auto" unmountOnExit={false}>
          <Grid container spacing={2}>
            {activePolls.map((poll) => (
              <Grid item key={poll._id}>
                <PollCard poll={poll} onClick={() => onPollClick(poll)} />
              </Grid>
            ))}
          </Grid>
        </Collapse>
      </Box>
      <Box mt={4}>
        <Button
          variant="text"
          endIcon={<RotatedExpandIcon expand={inactivePollsExpanded} />}
          onClick={() => setInactivePollsExpanded((value) => !value)}
          disabled={inactivePolls.length === 0}
          sx={{
            mb: 1,
          }}
        >
          <Typography>Geschlossen ({inactivePolls.length})</Typography>
        </Button>
        <Collapse
          in={inactivePollsExpanded}
          timeout="auto"
          unmountOnExit={false}
        >
          <Grid container spacing={2}>
            {inactivePolls.map((poll) => (
              <Grid item key={poll._id}>
                <PollCard poll={poll} onClick={() => onPollClick(poll)} />
              </Grid>
            ))}
          </Grid>
        </Collapse>
      </Box>
      <PollDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        initialValues={pollData}
      />
    </Box>
  );
};
export default PollGrid;
