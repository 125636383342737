import { Box, Button, Collapse, Typography, useTheme } from "@mui/material";
import { useState } from "react";

import RotatedExpandIcon from "./RotatedExpandIcon";

const CustomCard = ({
  children,
  title,
  expandable = false,
  defaultExpanded = false,
  unmountOnExit = true,
  fullHeight,
  id,
}) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <Box
      p="16px"
      borderRadius={theme.shape.borderRadius}
      sx={{ backgroundColor: theme.palette.background.paper }}
      display="flex"
      height={fullHeight && "100%"}
      flexDirection="column"
      id={id}
    >
      <Box display="flex" alignItems="center">
        {title && (
          <Box
            ml="-16px"
            pl="16px"
            sx={{
              borderLeftStyle: "solid",
              borderLeftColor: "primary.main",
              borderWidth: "1px",
            }}
          >
            {expandable ? (
              <Button
                onClick={() => setExpanded((value) => !value)}
                sx={{ m: "-6px -8px", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  color="primary.main"
                  letterSpacing={1}
                  sx={{
                    mr: "8px",
                  }}
                >
                  {title}
                </Typography>
                <RotatedExpandIcon expand={expanded} />
              </Button>
            ) : (
              <Typography
                variant="subtitle1"
                color="primary.main"
                letterSpacing={1}
              >
                {title}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      {expandable ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit={unmountOnExit}>
          <Box mt={title && "16px"}>{children}</Box>
        </Collapse>
      ) : (
        <Box mt={title && "16px"}>{children}</Box>
      )}
    </Box>
  );
};
export default CustomCard;
