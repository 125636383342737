import {
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import ApplyTopBar from "components/ApplyTopBar";
import CopsPieChart from "components/CopsPieChart";
import CustomCard from "components/CustomCard";
import Header from "components/Header";
import dayjs from "dayjs";
import RequirePermissionForRender from "features/auth/RequirePermissionForRender";
import { useGetNewsQuery } from "features/news/newsApiSlice";
import { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { Link } from "react-router-dom";
import CopsOnlineList from "features/panthorApi/CopsOnlineList";
import MadeByBadge from "components/MadeByBadge";
import PlayerCountsChart from "features/playerCounts/PlayerCountsChart";

const DashboardNewsCard = () => {
  const theme = useTheme();

  const { data: news, isSuccess: getNewsIsSuccess } = useGetNewsQuery(
    "newsList",
    {
      refetchOnMountOrArgChange: false,
    }
  );

  const [activeNews, setActiveNews] = useState([]);

  useEffect(() => {
    if (getNewsIsSuccess) {
      setActiveNews(
        news.ids?.reduce((filtered, id) => {
          const newsEntity = news.entities[id];

          if (
            dayjs().isBetween(dayjs(newsEntity.from), dayjs(newsEntity.until))
          ) {
            filtered.push(newsEntity);
          }
          return filtered;
        }, [])
      );
    }
  }, [news, getNewsIsSuccess]);

  return activeNews.length > 0 ? (
    <List disablePadding>
      {activeNews.map((news) =>
        !!news.url ? (
          <Link
            key={news.id}
            style={{
              color: theme.palette.text.primary,
              textDecoration: "none",
            }}
            to={news.url}
            target={news?.url.charAt(0) === "/" ? "_self" : "_blank"}
          >
            <ListItemButton>
              <ListItemIcon>
                <InfoOutlinedIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={news.message}
                secondary={dayjs(news.from).format("DD.MM.YYYY HH:mm")}
              />
              <ListItemIcon sx={{ ml: 2 }}>
                <LinkOutlinedIcon color="info" />
              </ListItemIcon>
            </ListItemButton>
          </Link>
        ) : (
          <ListItem key={news.id}>
            <ListItemIcon>
              <InfoOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={news.message}
              secondary={dayjs(news.from).format("DD.MM.YYYY HH:mm")}
            />
            <ListItemIcon sx={{ ml: 2 }}></ListItemIcon>
          </ListItem>
        )
      )}
    </List>
  ) : (
    <Chip
      icon={<InfoOutlinedIcon color="info" />}
      label={<Typography>Derzeit gibt es keine Neuigkeiten</Typography>}
    />
  );
};

const Dashboard = () => {
  useEffect(() => {
    document.title = `Polizei Verwaltung ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
    >
      <Box>
        <Header title="DASHBOARD" subtitle="Allgemeine Übersicht" />
        <RequirePermissionForRender permissions={["sendApplications"]}>
          <Grid container mb={2} spacing={2}>
            <Grid item xs={12} sm={12} md="auto">
              <CustomCard title="INTERESSE AM POLIZEIDIENST?">
                <ApplyTopBar />
              </CustomCard>
            </Grid>
          </Grid>
        </RequirePermissionForRender>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={6} xl={4}>
              <CustomCard title="POLIZISTEN">
                <Box height="400px">
                  <CopsPieChart />
                </Box>
              </CustomCard>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={4}>
              <CustomCard title="SPIELERZAHLEN">
                <Box height="400px">
                  <PlayerCountsChart />
                </Box>
              </CustomCard>
            </Grid>

            <Grid item xs={12} sm={12} lg={6} xl={4}>
              <CustomCard title="POLIZISTEN ONLINE">
                <Box height="400px" overflow="auto">
                  <CopsOnlineList />
                </Box>
              </CustomCard>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg="auto">
              <CustomCard title="NEUIGKEITEN">
                <Box maxHeight="300px" overflow="auto">
                  <DashboardNewsCard />
                </Box>
              </CustomCard>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box>
        <MadeByBadge />
      </Box>
    </Box>
  );
};

export default Dashboard;
