import { styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const RotatedExpandIcon = styled((props) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand, initRotation, targetRotation }) => ({
  transform: !expand
    ? `rotate(${initRotation || "360"}deg)`
    : `rotate(${targetRotation || "180"}deg)`,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
export default RotatedExpandIcon;
